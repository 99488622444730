export const LANGUAGES = {
  TEXT_SLOGAN_COMPANY: [
    'Cộng tác trên nền tảng Bảo mật đạt chuẩn HIPAA',
    'Collaboration on a HIPAA Certified Security Platform',
    'SLOGAN_COMPANY-FR'
  ],
  TEXT_LOG_IN: ['Đăng nhập', 'Log in', 'Log in-FR'],
  TEXT_DO_NOT_HAVE_AN_ACCOUNT_: [
    'Bạn chưa có tài khoản ?',
    'Do not have an Account ?',
    'Do not have an Account ?-FR'
  ],
  TEXT_REGISTER: ['Đăng ký', 'Register', 'Register-FR'],
  TEXT_PASSWORD: ['Mật khẩu', 'Password', 'Password-FR'],
  TEXT_FORGOT_PASSWORD: [
    'Quên mật khẩu?',
    'Forgot password',
    'Forgot password-FR'
  ],
  TEXT_OR: ['Hoặc', 'Or', 'Or-FR'],
  TEXT_LOGIN_WITH_OTP: [
    'Đăng nhập bằng OTP',
    'Login with OTP',
    'Login with OTP-FR'
  ],
  TEXT_ENTER_EMAIL_PHONE_NUMBER: [
    'Nhập email/số điện thoại',
    'Enter email/phone number',
    'Enter email/phone number-FR'
  ],
  TEXT_GET_OTP: ['Nhận OTP', 'Get OTP', 'Get OTP-FR'],
  TEXT_LOGIN_WITH_PASSWORD: [
    'Đăng nhập bằng mật khẩu',
    'Login with password',
    'Login with password-FR'
  ],
  TEXT_TERMS_AND_POLICY: [
    'Bằng cách đăng nhập/ đăng ký vào trang web này, bạn đã đọc và đồng ý với {0} cũng như {1} của HODO',
    "By logging in/registering to this website, you have read and agree to HODO's {0} and {1}.",
    'TERMS_AND_POLICY-FR'
  ],
  TEXT_TERMS_OF_SERVICE: [
    'Điều khoản dịch vụ',
    'Terms of Service',
    'Terms of Service-FR'
  ],
  TEXT_PRIVACY_POLICY: [
    'Chính sách quyền riêng tư',
    'Privacy Policy',
    'Privacy Policy-FR'
  ],
  TEXT_ENTER_EMAIL: ['Nhập email', 'Enter email', 'Enter email-FR'],
  TEXT_PASSWORD_RETRIEVAL: [
    'Lấy lại mật khẩu',
    'Password retrieval',
    'Password retrieval-FR'
  ],
  TEXT_DO_YOU_ALREADY_HAVE_AN_ACCOUNT: [
    'Anh/chị đã có tài khoản ?',
    'Do you already have an Account?',
    'Do you already have an Account?-FR'
  ],
  TEXT_FIRST_AND_LAST_NAME: [
    'Họ và tên',
    'First and last name',
    'First and last name-FR'
  ],
  TEXT_PHONE_NUMBER: ['Số điện thoại', 'Phone number', 'Phone number-FR'],
  TEXT_DOCTOR: ['Bác sĩ', 'Doctor', 'Doctor-FR'],
  TEXT_MEDICAL_OFFICER: [
    'Nhân viên Y Tế',
    'Medical Officer',
    'Medical Officer-FR'
  ],
  TEXT_SPECIALIST: ['Bác sĩ Chuyên khoa', 'Specialist', 'Specialist-FR'],
  TEXT_NURSING: ['Điều dưỡng', 'Nursing', 'Nursing-FR'],
  TEXT_OTHER: ['Khác', 'Other', 'Other-FR'],
  TEXT_IS_YOUR_TITLE: [
    'Chức danh của anh/chị là: Bác sĩ, Bác sĩ Chuyên Khoa, Điều dưỡng, hay Nhân viên Y tế, hay chức danh Khác?',
    'Is your title: Doctor, Specialist, Nurse, or Medical Officer, or Other?',
    'Is your title: Doctor, Specialist, Nurse, or Medical Officer, or Other?-FR'
  ],
  TEXT_RE_ENTER_THE_PASSWORD: [
    'Nhập lại mật khẩu',
    'Re-enter the password',
    'Re-enter the password-FR'
  ],
  TEXT_PASSWORD_CONSISTS_OF_6_12_CHARACTERS: [
    'Mật khẩu gồm 6-12 ký tự',
    'Password consists of 6-12 characters',
    'Password consists of 6-12 characters-FR'
  ],
  TEXT_CONTAINS_LOWERCASE_LETTERS__A_Z_: [
    'Có chữ cái thường (a-z)',
    'Contains lowercase letters (a-z)',
    'Contains lowercase letters (a-z)-FR'
  ],
  TEXT_CONTAINS_AT_LEAST_ONE_DIGIT: [
    'Có ít nhất một chữ số (0-9) hoặc ký tự đặc biệt (@ # $ _ . - & *)',
    'Contains at least one digit (0-9) or special character (@ # $ _ . - & *)',
    'Contains at least one digit (0-9) or special character (@ # $ _ . - & *)-FR'
  ],
  TEXT_VIETNAMESE: ['Tiếng Việt', 'Vietnamese', 'Vietnamese-FR'],
  TEXT_ENGLISH: ['Tiếng Anh', 'English', 'English-FR'],
  TEXT_FRENCH: ['Tiếng Pháp', 'French', 'French-FR'],
  TEXT_WELCOME_TO_HODO: [
    'Chào mừng bạn!',
    'Welcome to HODO!',
    'Welcome to HODO!-FR'
  ],
  TEXT_ENTER_OTP_CODE: [
    'Nhập mã xác thực OTP',
    'Enter OTP code',
    'Enter OTP code-FR'
  ],
  TEXT_A_OTP_CODE_HAD_SENT_TO_YOUR_EMAIL: [
    'Một mã OTP vừa được gửi đến Email',
    'A OTP code had sent to your email',
    'A OTP code had sent to your email-FR'
  ],
  TEXT_CHANGE_EMAIL: ['Đổi email', 'Change Email', 'Change Email-FR'],
  TEXT_SEND_EMAIL: ['Gửi email', 'Send Email', 'Send Email-FR'],
  TEXT_SEND_OTP_AGAIN: [
    'Gửi lại OTP sau',
    'Send OTP again',
    'Send OTP again-FR'
  ],
  TEXT_CONFIRMATION_PASSWORD_DOES_NOT_MATCH: [
    'Mật khẩu Không trùng khớp',
    'Confirmation password does not match',
    'Confirmation password does not match-FR'
  ],
  TEXT_VERIFICATION_EMAIL: [
    'Xác thực email',
    'Verification email',
    'Verification email-FR'
  ],
  TEXT_YOU_DID_NOT_RECEIVE_THE_EMAIL: [
    'Bạn không nhận được email?',
    'You did not receive the email?',
    'You did not receive the email?-FR'
  ],
  TEXT_MESSAGE_SENT_EMAIL: [
    'Chúng tôi đã gửi cho bạn một email tới {1}. Nhấp vào liên kết ở đó để hoàn thành việc đăng ký của bạn.',
    'MESSAGE_SENT_EMAIL-EN',
    'MESSAGE_SENT_EMAIL-FR'
  ]
}

export const LANGUAGE_KEYS = {
  TEXT_SLOGAN_COMPANY: 'TEXT_SLOGAN_COMPANY',
  TEXT_LOG_IN: 'TEXT_LOG_IN',
  TEXT_DO_NOT_HAVE_AN_ACCOUNT_: 'TEXT_DO_NOT_HAVE_AN_ACCOUNT_',
  TEXT_REGISTER: 'TEXT_REGISTER',
  TEXT_PASSWORD: 'TEXT_PASSWORD',
  TEXT_FORGOT_PASSWORD: 'TEXT_FORGOT_PASSWORD',
  TEXT_OR: 'TEXT_OR',
  TEXT_LOGIN_WITH_OTP: 'TEXT_LOGIN_WITH_OTP',
  TEXT_ENTER_EMAIL_PHONE_NUMBER: 'TEXT_ENTER_EMAIL_PHONE_NUMBER',
  TEXT_GET_OTP: 'TEXT_GET_OTP',
  TEXT_LOGIN_WITH_PASSWORD: 'TEXT_LOGIN_WITH_PASSWORD',
  TEXT_TERMS_AND_POLICY: 'TEXT_TERMS_AND_POLICY',
  TEXT_TERMS_OF_SERVICE: 'TEXT_TERMS_OF_SERVICE',
  TEXT_PRIVACY_POLICY: 'TEXT_PRIVACY_POLICY',
  TEXT_ENTER_EMAIL: 'TEXT_ENTER_EMAIL',
  TEXT_PASSWORD_RETRIEVAL: 'TEXT_PASSWORD_RETRIEVAL',
  TEXT_DO_YOU_ALREADY_HAVE_AN_ACCOUNT: 'TEXT_DO_YOU_ALREADY_HAVE_AN_ACCOUNT',
  TEXT_FIRST_AND_LAST_NAME: 'TEXT_FIRST_AND_LAST_NAME',
  TEXT_PHONE_NUMBER: 'TEXT_PHONE_NUMBER',
  TEXT_DOCTOR: 'TEXT_DOCTOR',
  TEXT_MEDICAL_OFFICER: 'TEXT_MEDICAL_OFFICER',
  TEXT_SPECIALIST: 'TEXT_SPECIALIST',
  TEXT_NURSING: 'TEXT_NURSING',
  TEXT_OTHER: 'TEXT_OTHER',
  TEXT_IS_YOUR_TITLE: 'TEXT_IS_YOUR_TITLE',
  TEXT_RE_ENTER_THE_PASSWORD: 'TEXT_RE_ENTER_THE_PASSWORD',
  TEXT_PASSWORD_CONSISTS_OF_6_12_CHARACTERS:
    'TEXT_PASSWORD_CONSISTS_OF_6_12_CHARACTERS',
  TEXT_CONTAINS_LOWERCASE_LETTERS__A_Z_:
    'TEXT_CONTAINS_LOWERCASE_LETTERS__A_Z_',
  TEXT_CONTAINS_AT_LEAST_ONE_DIGIT: 'TEXT_CONTAINS_AT_LEAST_ONE_DIGIT',
  TEXT_VIETNAMESE: 'TEXT_VIETNAMESE',
  TEXT_ENGLISH: 'TEXT_ENGLISH',
  TEXT_FRENCH: 'TEXT_FRENCH',
  TEXT_WELCOME_TO_HODO: 'TEXT_WELCOME_TO_HODO',
  TEXT_ENTER_OTP_CODE: 'TEXT_ENTER_OTP_CODE',
  TEXT_A_OTP_CODE_HAD_SENT_TO_YOUR_EMAIL:
    'TEXT_A_OTP_CODE_HAD_SENT_TO_YOUR_EMAIL',
  TEXT_CHANGE_EMAIL: 'TEXT_CHANGE_EMAIL',
  TEXT_SEND_EMAIL: 'TEXT_SEND_EMAIL',
  TEXT_SEND_OTP_AGAIN: 'TEXT_SEND_OTP_AGAIN',
  TEXT_CONFIRMATION_PASSWORD_DOES_NOT_MATCH:
    'TEXT_CONFIRMATION_PASSWORD_DOES_NOT_MATCH',
  TEXT_VERIFICATION_EMAIL: 'TEXT_VERIFICATION_EMAIL',
  TEXT_YOU_DID_NOT_RECEIVE_THE_EMAIL: 'TEXT_YOU_DID_NOT_RECEIVE_THE_EMAIL',
  TEXT_MESSAGE_SENT_EMAIL: 'TEXT_MESSAGE_SENT_EMAIL'
}
